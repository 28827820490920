import styled from 'styled-components'

import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
import LinkInline from '@totallymoney/ui/components/LinkInline'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'

import { cardsFaqsUrl } from '@constants/helpLinks'

const TermsContainer = styled.article`
    background-color: ${theme.productNeutral100};
`

const TermsBox = styled(Box)`
    margin: 0 auto;
    max-width: 1200px;
`

interface TermDescriptionsProps {
    showCreditCardTypesDescription?: boolean
    showExclusiveDescription?: boolean
    showMatchFactorDescription?: boolean
    showPostCaptureGuaranteedCreditLimitDescription?: boolean
    showPreApprovedDescription?: boolean
    showPrePopulation?: boolean
    showSeeParticularCardDescription?: boolean
    showVariableAprDescription?: boolean
}

const TermDescriptions = (props: TermDescriptionsProps) => {
    return (
        <TermsContainer>
            <TermsBox px={['space30', 'space80']} py="space50">
                {props.showMatchFactorDescription ? (
                    <Box mb="space60">
                        <Box mb="space15">
                            <Heading as="h3" variant="40">
                                What is Match Factor?
                            </Heading>
                        </Box>
                        <Stack space="space10">
                            <Text variant="100">
                                Match Factor analyses each product&apos;s
                                features and your eligibility, and ranks them
                                based on what we think is best for you. The
                                amount of commission we get paid by partners
                                does not influence the ranking.
                            </Text>
                            <Text variant="100">
                                Match Factor does not constitute financial
                                advice and you should always take the time to
                                understand the credit offers available to you.
                            </Text>
                        </Stack>
                    </Box>
                ) : null}
                {props && props.showCreditCardTypesDescription ? (
                    <Box mb="space60">
                        <Box mb="space15">
                            <Heading as="h3" variant="40">
                                What are the different types of credit card?
                            </Heading>
                        </Box>
                        <Text variant="100">
                            You can find out more information about the
                            different types of credit card in our{' '}
                            <LinkInline href={cardsFaqsUrl} target="_blank">
                                guide
                            </LinkInline>
                        </Text>
                    </Box>
                ) : null}
                {props.showPreApprovedDescription ? (
                    <Box mb="space60">
                        <Box mb="space15">
                            <Heading as="h3" variant="40">
                                What does ’pre-approved’ mean?
                            </Heading>
                        </Box>
                        <Text variant="100">
                            If you are pre-approved, this means that you will
                            get the card or loan, at the rate advertised,
                            providing the information you give us is accurate
                            and you pass the lender’s final checks, which
                            include fraud and responsible lending safeguards
                        </Text>
                    </Box>
                ) : null}

                <>
                    <Box mb="space60">
                        <Box mb="space15">
                            <Heading as="h3" variant="40">
                                What’s a credit limit?
                            </Heading>
                        </Box>
                        <Text variant="100">
                            A credit limit represents the maximum amount you can
                            borrow or spend on your card, and is set by the
                            lender. It’s based on multiple factors, including
                            your credit history and income.
                        </Text>
                    </Box>
                    <Box mb="space60">
                        <Box mb="space15">
                            <Heading as="h3" variant="40">
                                Why don’t I have offers with a guaranteed credit
                                limit?
                            </Heading>
                        </Box>
                        <Text variant="100">
                            If you can’t see any offers with a guaranteed credit
                            limit, this is because no lenders can offer you a
                            card with the amount you entered. You could try
                            decreasing it to see if you get any guaranteed limit
                            offers.
                        </Text>
                    </Box>
                </>
                {props.showPostCaptureGuaranteedCreditLimitDescription ? (
                    <Box mb="space60">
                        <Box mb="space15">
                            <Heading as="h3" variant="40">
                                What’s a minimum guaranteed credit limit?
                            </Heading>
                        </Box>
                        <Text variant="100">
                            A minimum guaranteed credit limit represents the
                            minimum amount of money a lender can guarantee
                            upfront, if you’re approved. This provides you with
                            valuable information to make informed decisions,
                            prior to applying. Note that any application is
                            subject to final checks.
                        </Text>
                    </Box>
                ) : null}

                {props.showExclusiveDescription ? (
                    <Box mb="space60">
                        <Box mb="space15">
                            <Heading as="h3" variant="40">
                                Exclusive Offer
                            </Heading>
                        </Box>
                        <Text variant="100">
                            Exclusive to the TotallyMoney comparison platform.
                            This offer may appear on sites that we power or
                            advertise through.
                        </Text>
                    </Box>
                ) : null}
                {props.showPrePopulation ? (
                    <Box mb="space60">
                        <Box mb="space15">
                            <Heading as="h3" variant="40">
                                Pre-population
                            </Heading>
                        </Box>
                        <Text variant="100">
                            When you click to go to a lender we may pass over
                            your details to help speed up the application
                            process
                        </Text>
                    </Box>
                ) : null}
                {props.showVariableAprDescription ? (
                    <Box mb="space60">
                        <Box mb="space15">
                            <Heading as="h3" variant="40">
                                What does variable APR mean?{' '}
                            </Heading>
                        </Box>
                        <Text variant="100">
                            Variable APR means that the annual percentage rate
                            on your credit card can change over time. Most
                            lenders base the variable rate on the Bank of
                            England Base Rate, so your rate will change
                            automatically in line with changes to the Base Rate.
                            The lender will give you notice of any changes.{' '}
                        </Text>
                    </Box>
                ) : null}
                {props.showSeeParticularCardDescription ? (
                    <Box>
                        <Box mb="space15">
                            <Heading as="h3" variant="40">
                                Can’t see a particular card?
                            </Heading>
                        </Box>
                        <Text variant="100">
                            We frequently update our product offering meaning
                            the cards shown on this table may change. If you
                            can’t find what you are looking for, this may be
                            because we have recently updated our table and
                            removed a particular product.
                        </Text>
                    </Box>
                ) : null}
            </TermsBox>
        </TermsContainer>
    )
}

export default TermDescriptions
