import { GuaranteedOfferType } from '@appTypes/App.interface'

import { getFormattedAPR, getFormattedMonth } from '@utils/formatter'
import getGuaranteedOfferType from '@utils/getGuaranteedOfferType'

import getBalanceTransferOfferDurationMonths from '../../../utils/getBalanceTransferOfferDurationMonths'
import getShowGuaranteedExample from '../utils/getShowGuaranteedExample'
import getShowRepresentativeExample from '../utils/getShowRepresentativeExample'
import { ContentProps, ProductDetailType } from './Content.interface'
import ContentGuaranteedExample from './ContentGuaranteedExample'
import ContentMinimumGuaranteedCreditLimit from './ContentMinimumGuaranteedCreditLimit'
import ContentNoMinimumGuaranteedCreditLimit from './ContentNoMinimumGuaranteedCreditLimit'
import ContentRepresentativeExample from './ContentRepresentativeExample'

const ContentBestCards = ({
    creditCard,
    isMediaLargePlus,
    renderProductDetail,
    creditLimitProductDetailVariant,
    onShowCreditLimitModal,
    onShowMinimumCreditLimitModal,
    shouldHideCreditLimit,
}: ContentProps) => {
    const { guaranteedCreditLimit } = creditCard.guaranteedRate

    const balanceTransferOfferDurationMonths =
        getBalanceTransferOfferDurationMonths(creditCard)

    const isMarketingOffer =
        getGuaranteedOfferType({
            creditCard,
        }) === GuaranteedOfferType.MarketingOffer

    const monthDisplay = isMediaLargePlus ? 'long' : 'short'

    return (
        <>
            {renderProductDetail({
                title: 'Balance Transfer offer duration',
                value: getFormattedMonth(
                    balanceTransferOfferDurationMonths,
                    monthDisplay
                ),
                detailType: isMarketingOffer
                    ? ProductDetailType.GuaranteedExample
                    : undefined,
            })}
            {renderProductDetail({
                title: '0% Purchase offer duration',
                value: getFormattedMonth(
                    creditCard.offerDetail.durationOfPurchaseRateMonths,
                    monthDisplay
                ),
                detailType: isMarketingOffer
                    ? ProductDetailType.GuaranteedExample
                    : undefined,
            })}
            {getShowGuaranteedExample(creditCard) ? (
                <ContentGuaranteedExample
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                />
            ) : null}
            {getShowRepresentativeExample(creditCard) ? (
                <ContentRepresentativeExample
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                />
            ) : null}
            {guaranteedCreditLimit != null &&
            creditLimitProductDetailVariant !== 'setLimit' ? (
                <ContentMinimumGuaranteedCreditLimit
                    creditCard={creditCard}
                    renderProductDetail={renderProductDetail}
                />
            ) : null}
            {!shouldHideCreditLimit &&
            creditLimitProductDetailVariant != null ? (
                <ContentNoMinimumGuaranteedCreditLimit
                    creditCard={creditCard}
                    creditLimitProductDetailVariant={
                        creditLimitProductDetailVariant
                    }
                    renderProductDetail={renderProductDetail}
                    onShowCreditLimitModal={onShowCreditLimitModal}
                    onShowMinimumCreditLimitModal={
                        onShowMinimumCreditLimitModal
                    }
                />
            ) : null}
            {renderProductDetail({
                title: 'Transfer Fee',
                value: getFormattedAPR(
                    creditCard.offerDetail.introBalanceTransferFee
                ),
            })}
        </>
    )
}

export default ContentBestCards
