import Image from 'next/legacy/image'

import Box from '@totallymoney/ui/components/Box'
import Modal from '@totallymoney/ui/components/Modal'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'

import insightsLightBulb from '../../public/images/insights-lightbulb.svg'

interface CreditLimitModalProps {
    onModalClose: () => void
    showModal: boolean
}

const CreditLimitModal = ({
    showModal,
    onModalClose,
}: CreditLimitModalProps) => {
    return (
        <Modal
            title="What’s a credit limit?"
            hasHeader={false}
            hasTooltipHeader={true}
            isOpen={showModal}
            onClose={onModalClose}
            isFullScreen={true}
            hasMinHeight={false}
        >
            <Stack space="space20">
                <Stack space="space30">
                    <Text color="light">
                        A credit limit represents the maximum amount you can
                        borrow or spend on your card, and is set by the lender.
                        It’s based on multiple factors, including your credit
                        history and income.
                    </Text>
                    <Box display="flex">
                        <Box mr="space10">
                            <Image
                                src={insightsLightBulb}
                                alt="Light bulb with a light indigo background"
                            />
                        </Box>
                        <Box flex={1}>
                            <Text color="dark" variant="100">
                                <span style={{ fontWeight: 'bold' }}>Tip:</span>{' '}
                                By entering a guaranteed credit limit, you can
                                remove some of the uncertainty your application.
                                And if we can’t offer you what you’ve requested,
                                we might still be able to offer a slightly lower
                                guaranteed limit. Note you would still need to
                                be approved after you apply.
                            </Text>
                        </Box>
                    </Box>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default CreditLimitModal
