import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

import { getFormattedCurrency } from '@utils/formatter'

import { ContentProps, ProductDetailType } from './Content.interface'

function getGuaranteedCreditLimitTitle({
    creditCard,
    category,
}: Omit<ContentProps, 'renderProductDetail'>) {
    if (
        category !== CreditCardProductCategory.BalanceTransfer &&
        creditCard.balanceTransferComboLimit
    ) {
        return 'Total Guaranteed Credit Limit'
    }
    if (
        category === CreditCardProductCategory.BalanceTransfer &&
        creditCard.balanceTransferComboLimit
    ) {
        return 'Guaranteed BT Credit Limit'
    }
    return 'Guaranteed Credit Limit'
}

export function getGuaranteedCreditLimitValue({
    creditCard,
    category,
}: Omit<ContentProps, 'renderProductDetail'>) {
    if (
        category === CreditCardProductCategory.BalanceTransfer &&
        creditCard.balanceTransferComboLimit
    ) {
        return creditCard.balanceTransferComboLimit
    }
    return creditCard.guaranteedRate.guaranteedCreditLimit
}

const ContentGuaranteedCreditLimit = ({
    creditCard,
    category,
    renderProductDetail,
}: ContentProps) => {
    const title = getGuaranteedCreditLimitTitle({ creditCard, category })
    const value = getGuaranteedCreditLimitValue({
        creditCard,
        category,
    })

    return renderProductDetail({
        title,
        value: getFormattedCurrency(value),
        detailType: ProductDetailType.GuaranteedCreditLimit,
    })
}

export default ContentGuaranteedCreditLimit
