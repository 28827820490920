import {
    CreditCardProductCategory,
    ScoredCreditCardProduct,
} from '@appTypes/EligibilityApi.interface'

import { getCardHasOfferDuration } from '@utils/getCardHasOfferDuration'

interface Options {
    card: ScoredCreditCardProduct | null
    category: CreditCardProductCategory
}

const offerDurationCategories = [
    CreditCardProductCategory.BestCards,
    CreditCardProductCategory.Purchase,
    CreditCardProductCategory.BalanceTransfer,
    CreditCardProductCategory.Combined,
]

export function getShowOfferDurationContent({ category, card }: Options) {
    const hasOfferDurationCategory = offerDurationCategories.includes(category)
    return hasOfferDurationCategory && getCardHasOfferDuration(card)
}
