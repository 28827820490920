import Image from 'next/legacy/image'

import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import AlertIconBadge from '@totallymoney/ui/icons/AlertIconBadge'

import insightsLightBulb from '../../../public/images/insights-lightbulb.svg'
import GuaranteedAprExplanation from './components/GuaranteedAprExplanation'

const ContentGuaranteedAprAndCreditLimit = () => {
    return (
        <Stack space="space40">
            <GuaranteedAprExplanation />

            <Stack space="space20">
                <Box display="flex" alignItems="center">
                    <Box mr="space20">
                        <AlertIconBadge />
                    </Box>
                    <Heading variant="60">
                        What is Guaranteed Credit Limit?
                    </Heading>
                </Box>
                <Text color="light">
                    The Guaranteed Credit Limit represents the minimum amount of
                    money that a lender can guarantee upfront, providing you
                    with valuable information to make informed decisions prior
                    to initiating your application process.
                </Text>
                <Box display="flex">
                    <Box mr="space10">
                        <Image
                            src={insightsLightBulb}
                            alt="Light bulb with a light indigo background"
                        />
                    </Box>
                    <Box flex={1}>
                        <Text color="light" variant="100">
                            <span style={{ fontWeight: 'bold' }}>Tip:</span> You
                            may see offers below the amount you entered. That’s
                            because lenders may not be able to offer you the
                            full amount, but might be able to do slightly under.
                            The amount is still guaranteed, and you’ll still
                            need to be accepted for the offer.
                        </Text>
                    </Box>
                </Box>
            </Stack>
        </Stack>
    )
}

export default ContentGuaranteedAprAndCreditLimit
