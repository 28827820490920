import Box from '@totallymoney/ui/components/Box'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import Icon from '@totallymoney/ui/icons/Icon'
import theme from '@totallymoney/ui/theme'

import { StyledPreApprovedIndicator } from './PreApprovedIndicator.styled'

interface PreApprovedIndicatorProps {
    apr: number | string
    id: string
    onPress: () => void
}

const PreApprovedIndicator = ({
    id,
    apr,
    onPress,
}: PreApprovedIndicatorProps) => (
    <StyledPreApprovedIndicator
        as="button"
        display="flex"
        onClick={onPress}
        justifyContent={['center', 'unset']}
    >
        <Box
            display="flex"
            flexDirection="column"
            width={['100%', '208px']}
            borderRadius="borderRadius10"
            backgroundColor="eligibilityLevel0-10"
        >
            <Stack
                space={['space05', 'space10']}
                direction="row"
                width="100%"
                justifyContent="center"
                alignItems="center"
                py="space05"
                px="space10"
                backgroundColor="eligibilityLevel0-100"
                borderRadius="borderRadius10"
            >
                <Icon
                    icon="checkmark"
                    size={14}
                    color="white"
                    title={`Pre approved tick for ${id}`}
                />

                <Text
                    variant="80"
                    color="white"
                    textAlign="center"
                    style={{ fontFamily: theme.buenosAires }}
                >
                    Pre-approved
                </Text>

                <Icon
                    icon="tooltip"
                    size={20}
                    color="white"
                    title={`Pre approved tooltip for ${id}`}
                />
            </Stack>

            <Box
                display="flex"
                width="100%"
                justifyContent="center"
                alignItems="center"
                backgroundColor="eligibilityLevel0-10"
                p="space10"
                borderBottomLeftRadius="borderRadius10"
                borderBottomRightRadius="borderRadius10"
            >
                <Text variant="60" textAlign="center" color="dark">
                    At <b>{`${apr}%`} APR</b> <br />
                    (subject to final checks)
                </Text>
            </Box>
        </Box>
    </StyledPreApprovedIndicator>
)

export default PreApprovedIndicator
