const TmExclusiveIcon = ({ size = 16 }: { size?: number }) => (
    <svg
        width={`${size}`}
        height={`${size + 1}`}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="21"
            height="20"
        >
            <ellipse cx="10.044" cy="10" rx="10.0167" ry="10" fill="#170F57" />
        </mask>
        <g mask="url(#mask0)">
            <ellipse cx="10.044" cy="10" rx="10.0167" ry="10" fill="#170F57" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8868 3.14375C10.7609 3.20304 10.6106 3.30171 10.5116 3.41282C10.037 3.94578 9.7199 4.68645 9.52245 5.19722C9.2258 5.96532 8.9862 6.74827 8.81034 7.52439C8.72406 7.90495 8.65237 8.28775 8.59516 8.67246C8.62155 8.64524 8.64794 8.61737 8.6747 8.58868C8.88951 8.35817 9.09332 8.11097 9.28013 7.8539C9.74378 7.21621 10.1181 6.50107 10.3928 5.72843C10.7512 4.72011 10.9136 3.89069 10.8891 3.19273C10.8883 3.17522 10.8878 3.15886 10.8868 3.14375ZM11.5435 18.9342C10.397 18.9342 9.24544 18.5071 8.4002 17.7159C7.39372 16.7737 6.77445 15.36 6.50728 13.3939C6.42539 12.7928 6.37399 12.1916 6.35279 11.5917C6.17478 11.5725 5.99181 11.5451 5.80264 11.5093C2.13082 10.8161 -0.401612 6.778 0.157399 2.50796C0.549358 -0.4868 2.51509 -3.29344 5.41549 -5L6.45642 -3.15555C4.11316 -1.77675 2.53069 0.445873 2.22388 2.79002C1.8097 5.95333 3.58535 8.92578 6.18173 9.41615C6.25882 9.43066 6.33313 9.44326 6.40535 9.45389C6.47416 8.64455 6.59874 7.84033 6.77888 7.04467C6.97804 6.16606 7.24852 5.28182 7.5831 4.41613C7.84044 3.74991 8.26402 2.7724 8.96904 1.98073C9.49626 1.38913 10.4836 0.824965 11.4042 0.983964C12.0287 1.09191 12.5162 1.4956 12.777 2.12064C12.9344 2.49689 12.9641 2.86938 12.9726 3.11669C13.0069 4.09278 12.804 5.18473 12.3525 6.45477C12.009 7.42131 11.5384 8.31913 10.9541 9.12286C10.7167 9.44915 10.458 9.76317 10.185 10.0562C9.61432 10.6685 9.04666 11.0917 8.43123 11.3427C8.4455 11.9277 8.49246 12.5146 8.57222 13.1013C8.77025 14.5568 9.17477 15.5529 9.80905 16.1468C10.4698 16.7653 11.5192 16.9777 12.3614 16.6631C13.2033 16.3485 13.8707 15.4946 13.9846 14.5867L16.0526 14.8569C15.8408 16.5474 14.6453 18.0765 13.078 18.6622C12.5873 18.8455 12.0659 18.9342 11.5435 18.9342Z"
                fill="white"
            />
        </g>
    </svg>
)

export default TmExclusiveIcon
