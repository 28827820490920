import styled from 'styled-components'

import Box from '@totallymoney/ui/components/Box'
import { focusOutlineStyle } from '@totallymoney/ui/styles/focus'
import theme from '@totallymoney/ui/theme'

export const StyledPreApprovedIndicator = styled(Box)`
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 100%;

    &:active,
    &:focus {
        ${focusOutlineStyle};
        border-radius: ${theme.radii.borderRadius05};
    }
`
