export enum SessionStorageItem {
    newCardProductCodes = 'newCardProductCodes',
}

const isSessionStorageAvailable = (): boolean => {
    try {
        window.sessionStorage.setItem('storageTest', 'storageTest')
        window.sessionStorage.removeItem('storageTest')
        return true
    } catch (e) {
        return false
    }
}

export const setSessionStorageItem = (
    itemKey: SessionStorageItem,
    itemValue: string
): void => {
    if (isSessionStorageAvailable()) {
        window.sessionStorage.setItem(itemKey, itemValue)
    }
}

export const getSessionStorageItem = (
    itemKey: SessionStorageItem
): string | null => {
    if (isSessionStorageAvailable()) {
        return window.sessionStorage.getItem(itemKey)
    }
    return null
}

export const removeSessionStorageItem = (itemKey: SessionStorageItem): void => {
    if (isSessionStorageAvailable()) {
        window.sessionStorage.removeItem(itemKey)
    }
}
