import { ReactNode } from 'react'

import Box from '@totallymoney/ui/components/Box'
import Text, { TextProps } from '@totallymoney/ui/components/Text'

const UnorderedList = ({ children }: { children: ReactNode }) => (
    <Box
        as="ul"
        display="grid"
        gridGap="space10"
        pl="space30"
        style={{ listStyleType: 'disc' }}
    >
        {children}
    </Box>
)

export const ListItem = ({
    children,
    textColor = 'light',
}: {
    children: ReactNode
    textColor?: TextProps['color']
}) => (
    <Box as="li" pl="space10">
        <Text color={textColor}>{children}</Text>
    </Box>
)

export default UnorderedList
