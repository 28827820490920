import { useQuery } from '@tanstack/react-query'

import {
    CreditCardProductCategory,
    FetchScoredCreditCardProductsResponse,
} from '@appTypes/EligibilityApi.interface'

import { useBasin } from '@hooks/useBasin'
import useFeatureFlag from '@hooks/useFeatureFlag'

import { getAuthErrorInfo } from '@utils/getAuthErrorInfo'

import getAuthHeaders from './utils/getAuthHeaders'
import { handleApiResponse } from './utils/handleApiResponse'

interface Options<TSelectResult> {
    body: {
        category: CreditCardProductCategory
        customerId: string
        scoreId: string
    }
    select?: (
        cardsResponse: FetchScoredCreditCardProductsResponse
    ) => TSelectResult
}

export const useScoredCategoriesQuery = <TSelectResult>({
    body,
    select,
}: Options<TSelectResult>) => {
    const { ids } = useBasin()
    const experiment_useMatchFactorRanking = useFeatureFlag(
        'experiment_useMatchFactorRanking'
    )
    const experiment_useTokens = useFeatureFlag('experiment_useTokens')

    return useQuery(
        ['scoredCategories', body.scoreId, body.customerId],
        () =>
            fetch(
                `${process.env.NEXT_PUBLIC_BASE_PATH}/api/scored/?scoreId=${body.scoreId}&customerId=${body.customerId}&category=${body.category}&experiment_useMatchFactorRanking=${experiment_useMatchFactorRanking}&experiment_useTokens=${experiment_useTokens}`,
                {
                    headers: {
                        'content-type': 'application/json',
                        ...getAuthHeaders(ids),
                    },
                }
            ).then(handleApiResponse),
        {
            select,
            networkMode:
                process.env.NODE_ENV === 'development' ? 'always' : 'online',
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            enabled: !!body.scoreId && !!body.customerId,
            retry: (failureCount, error) => {
                return getAuthErrorInfo(error) == null && failureCount < 3
            },
        }
    )
}
