import styled from 'styled-components'

import Box from '@totallymoney/ui/components/Box'
import { focusStylesWithRoundedEdges } from '@totallymoney/ui/styles/focus'
import theme from '@totallymoney/ui/theme'

export const PercentageScoreContainer = styled.a`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-decoration: none;
    width: 100%;

    ${focusStylesWithRoundedEdges};

    ${theme.mediaMediumPlus`
        width: 220px;
    `}
`

export const ScoreBarInner = styled(Box)`
    transition: width 0.6s ease-out;
`
