export enum LocalStorageItem {
    hasSeenImproveEligibilityPromotion = 'hasSeenImproveEligibilityPromotion',
    previousCreditCardOffers = 'previousCreditCardOffers',
    previousCreditCardProductCodes = 'previousCreditCardProductCodes',
}

const isLocalStorageAvailable = (): boolean => {
    try {
        window.localStorage.setItem('storageTest', 'storageTest')
        window.localStorage.removeItem('storageTest')
        return true
    } catch (e) {
        return false
    }
}

export const setLocalStorageItem = (
    itemKey: LocalStorageItem,
    itemValue: string
): void => {
    if (isLocalStorageAvailable()) {
        window.localStorage.setItem(itemKey, itemValue)
    }
}

export const getLocalStorageItem = (
    itemKey: LocalStorageItem
): string | null => {
    if (isLocalStorageAvailable()) {
        return window.localStorage.getItem(itemKey)
    }
    return null
}

export const removeLocalStorageItem = (itemKey: LocalStorageItem): void => {
    if (isLocalStorageAvailable()) {
        window.localStorage.removeItem(itemKey)
    }
}
