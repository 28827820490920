import Box from '@totallymoney/ui/components/Box'
import Button from '@totallymoney/ui/components/Button'
import Heading from '@totallymoney/ui/components/Heading'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'

import TooltipButton from '@components/TooltipButton'

import { ContentProps, ProductDetailType } from './Content.interface'

const ContentNoMinimumGuaranteedCreditLimit = ({
    creditLimitProductDetailVariant,
    renderProductDetail,
    onShowCreditLimitModal,
    onShowMinimumCreditLimitModal,
}: ContentProps) =>
    renderProductDetail({
        title: (
            <Stack
                space="space10"
                flexDirection="row"
                style={{ whiteSpace: 'nowrap' }}
            >
                <Text variant="100" color="light">
                    Credit limit
                </Text>

                <TooltipButton
                    size={20}
                    color={theme.grey100}
                    onPress={onShowCreditLimitModal!}
                    title="Tooltip icon for credit limit"
                />
            </Stack>
        ),

        value: (
            <Box>
                {creditLimitProductDetailVariant === 'application' ? (
                    <Heading variant="40" textAlign="left" color="light">
                        Upon application
                    </Heading>
                ) : null}

                {creditLimitProductDetailVariant === 'setLimit' ? (
                    <Button
                        variant="tertiaryTransparent"
                        onClick={onShowMinimumCreditLimitModal}
                        style={{
                            fontSize: '18px',
                            padding: `0 ${theme.space10}`,
                            minHeight: 'unset',
                        }}
                    >
                        Enter limit
                    </Button>
                ) : null}
            </Box>
        ),

        detailType:
            creditLimitProductDetailVariant === 'setLimit'
                ? ProductDetailType.setLimit
                : undefined,
    })

export default ContentNoMinimumGuaranteedCreditLimit
