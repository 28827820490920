import Head from 'next/head'

import { getAppConfig } from '@utils/getAppConfig'

interface MetadataProps {
    metadata?: {
        canonical?: string
        description?: string
        title?: string
    }
}

const config = getAppConfig()

const MetadataHead = ({ metadata }: MetadataProps) => {
    /**
     * The whitelabel that we primarily use for the unscored
     * journey is "creditcards", therefore we can index these.
     * However, the other whitelabels are for scored journeys so
     * we don't need to index these.
     */
    const isUnscoredWhitelabel = config.NAME_WHITELABEL === 'creditcards'

    return (
        <Head>
            {metadata != null && isUnscoredWhitelabel ? (
                <meta name="robots" content="index, follow" />
            ) : (
                <meta name="robots" content="noindex, nofollow" />
            )}
            {metadata?.title ? (
                <title>{metadata.title}</title>
            ) : (
                <title>
                    Compare the UK’s Top Credit Cards with TotallyMoney
                </title>
            )}
            {metadata?.description ? (
                <meta name="description" content={metadata.description} />
            ) : null}
            {metadata?.canonical ? (
                <link rel="canonical" href={metadata.canonical} />
            ) : null}
        </Head>
    )
}

export default MetadataHead
