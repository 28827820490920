import { useEffect, useRef, useState } from 'react'

import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'

import { eligibilityForCreditProductFaqUrl } from '@constants/helpLinks'

import {
    PercentageScoreContainer,
    ScoreBarInner,
} from './EligibilityIndicator.styled'
import { getEligibilityVariantOptions } from './utils/getEligibilityVariantOptions'

const EligibilityIndicator = ({
    score,
}: {
    score?: number
}): React.ReactElement => {
    const [transition, setTranstion] = useState(0)

    const refContainer = useRef<HTMLAnchorElement | null>(null)

    const variantOptions = getEligibilityVariantOptions(score)

    const checkVisible = () => {
        if (refContainer.current == null) {
            return
        }

        var rect = refContainer.current.getBoundingClientRect()
        var viewHeight = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight
        )

        return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
    }

    useEffect(
        function mount() {
            function onScroll() {
                if (refContainer != null && score != null && checkVisible()) {
                    setTranstion(score)
                }
            }

            if (refContainer != null && score != null && checkVisible()) {
                setTranstion(score)
            }

            window.addEventListener('scroll', onScroll)

            return function unMount() {
                window.removeEventListener('scroll', onScroll)
            }
        },
        [score]
    )

    return (
        <PercentageScoreContainer
            ref={refContainer}
            href={eligibilityForCreditProductFaqUrl}
            target="_blank"
        >
            <Box p="space10">
                <Heading textAlign="center" variant="20">
                    {variantOptions.text}
                </Heading>
            </Box>

            <Box backgroundColor="grey20" borderRadius="borderRadius20">
                <ScoreBarInner
                    data-testid="eligibilityIndicatorScoreBar"
                    backgroundColor={variantOptions.indicatorBoxBg}
                    width={`${transition}%`}
                    height="8px"
                    borderTopLeftRadius="borderRadius20"
                    borderBottomLeftRadius="borderRadius20"
                />
            </Box>

            {score != null ? (
                <Box p="space10">
                    <Heading textAlign="center" variant="10" color="light">
                        {`${score}% chance of acceptance`}
                    </Heading>
                </Box>
            ) : null}
        </PercentageScoreContainer>
    )
}

export default EligibilityIndicator
