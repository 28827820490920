import ButtonWithTooltip from '@totallymoney/ui/components/ButtonWithTooltip'

interface GuaranteedOfferQuestionProps {
    onTooltipPress: () => void
}

const GuaranteedOfferQuestion = ({
    onTooltipPress,
}: GuaranteedOfferQuestionProps) => (
    <ButtonWithTooltip
        direction="left"
        text="What’s a guaranteed offer?"
        onClick={onTooltipPress}
    />
)

export default GuaranteedOfferQuestion
