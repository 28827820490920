export enum SemanticEligibilityScore {
    Average = 'Average',
    Excellent = 'Excellent',
    Good = 'Good',
    NotEligible = 'Not Eligible',
    NotRated = 'Not rated',
    Poor = 'Poor',
    PreApproved = 'Pre Approved',
    VeryGood = 'Very good',
}

export function getSemanticEligibilityScore(score?: number) {
    if (score == null) {
        return SemanticEligibilityScore.NotRated
    }
    if (score === 0) {
        return SemanticEligibilityScore.NotEligible
    }
    if (score > 0 && score <= 50) {
        return SemanticEligibilityScore.Poor
    }
    if (score >= 51 && score <= 70) {
        return SemanticEligibilityScore.Average
    }
    if (score > 70 && score <= 80) {
        return SemanticEligibilityScore.Good
    }
    if (score > 80 && score <= 90) {
        return SemanticEligibilityScore.VeryGood
    }
    if (score > 90 && score <= 99) {
        return SemanticEligibilityScore.Excellent
    }
    if (score === 100) {
        return SemanticEligibilityScore.PreApproved
    }
    return null
}
