import Box, { BoxProps } from '@totallymoney/ui/components/Box'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import Icon from '@totallymoney/ui/icons/Icon'
import theme from '@totallymoney/ui/theme'

export type BannerOfferVariant = 'default' | 'backdrop' | 'gcl'

interface BannerOfferProps {
    backgroundColor: string
    description: string
    prefix?: React.ReactNode
    title: string
    variant?: BannerOfferVariant
}

const BannerOffer = ({
    title,
    description,
    prefix,
    backgroundColor,
    variant = 'default',
}: BannerOfferProps) => {
    const hasPrefix = prefix != null

    const variantRowProps: Partial<BoxProps> = (() => {
        switch (variant) {
            case 'default': {
                return {
                    display: 'inline-flex',
                    borderRadius: theme.radii.borderRadius05,
                    pb: theme.space10,
                }
            }
            case 'backdrop':
            case 'gcl': {
                return {
                    display: 'flex',
                    borderTopLeftRadius: theme.radii.borderRadius20,
                    borderTopRightRadius: theme.radii.borderRadius20,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    px: theme.space20,
                    pb: theme.space30,
                }
            }
        }
    })()

    return (
        <Box
            display="inline-flex"
            flexWrap="wrap"
            px="space20"
            py="space10"
            backgroundColor={backgroundColor}
            {...variantRowProps}
        >
            {hasPrefix ? (
                <Box pt="space025" pr="space10">
                    {prefix}
                </Box>
            ) : null}

            {variant === 'gcl' ? (
                <Stack
                    alignItems="center"
                    direction="row"
                    py="space05"
                    space="space10"
                >
                    <Icon icon="checkmark" size={16} color="white" />

                    <Text
                        variant="80"
                        weight="bold"
                        style={{ fontFamily: theme.buenosAires }}
                        color="white"
                    >
                        {title}
                    </Text>
                </Stack>
            ) : null}

            {variant == 'default' || variant === 'backdrop' ? (
                <Box flex={1}>
                    <Text
                        variant="80"
                        weight="bold"
                        style={{ fontFamily: theme.buenosAires }}
                        color="light"
                    >
                        {title}
                    </Text>

                    <Text variant="80" color="light">
                        {description}
                    </Text>
                </Box>
            ) : null}
        </Box>
    )
}

export default BannerOffer
